<template>
  <div v-show="!imgDialogVisible && !showWarningDetialDialog" class="warning-detial-box" :style="`top: ${top}px; right: ${right}px`">
    <div class="warning-detial-box-top">
        <i 
            class="el-icon-close icon-close"
            @click="close">
        </i>
        <div class="status-box" v-if="isAI">
            <span class="status confirmed" v-if="selectWarning.alarmStatus === 'confirm'">{{$t('warning.confirmed')}}</span>
            <span class="status corrected" v-else-if="selectWarning.alarmStatus === 'amend'">{{$t('warning.corrected')}}</span>
            <span class="status neglected" v-else-if="selectWarning.alarmStatus === 'ignore'">{{$t('warning.neglected')}}</span>
            <span class="status untreated" v-else>{{$t('warning.untreated')}}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('warning.type') }}:</span>
            <span class="info-content" v-if="!isAI">{{ selectWarning.alarmTypeName }}</span>
            <span class="info-content" v-else>{{ selectWarning.finalTypeName }}</span>
        </div>
        <div class="info-line" v-if="isAI && selectWarning.alarmStatus === 'amend'">
            <span class="info-name">{{$t('warning.correctionType')}}:</span>
            <span class="info-content">{{selectWarning.firstTypeName}}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('warning.place') }}:</span>
            <span class="info-content" v-if="!isAI">{{ selectWarning.address }}</span>
            <span class="info-content" v-else>{{ selectWarning.alarmAddress }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('warning.device') }}:</span>
            <span class="info-content" v-if="!isAI">
                {{ (selectWarning && selectWarning.uavName)? selectWarning.uavName.join(','): '' }}</span>
            <span class="info-content" v-else>{{ selectWarning.equipmentName }}</span>
        </div>
        <div class="info-line" v-if="selectWarning.level">
            <span class="info-name">{{ $t('warning.alarmLevel') }}:</span>
            <span class="info-content">{{alarmLevel(selectWarning.level)}}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('FilghtProjectName') }}:</span>
            <span class="info-content">{{ selectWarning.projectName }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('FilghtTask') }}:</span>
            <span class="info-content">{{ selectWarning.taskName }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('inspection.project') }}:</span>
            <span class="info-content">{{ selectWarning.inpectionProjectName }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('inspection.selfName') }}:</span>
            <span class="info-content">{{ selectWarning.inpectionObjectName }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('warning.time') }}:</span>
            <span class="info-content">{{ selectWarning.alarmTime }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('longitude') }}:</span>
            <span class="info-content">{{ selectWarning.lng }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{ $t('latitude') }}:</span>
            <span class="info-content">{{ selectWarning.lat }}</span>
        </div>
        <div class="info-line">
            <span class="info-name">{{$t('warning.photo')}}:</span>
            <div class="img-box" v-if="selectWarning.imgs && selectWarning.imgs.length">
                <el-image 
                    style="width: 96px; margin-right: 8px"
                    v-for="item in selectWarning.imgs"
                    fit="fill"
                    @close="handleClose"
                    @click="showImgDialog(item)"
                    :key="item"
                    :src="item" 
                    :preview-src-list="selectWarning.imgs">
                </el-image>
            </div>
        </div>
        <div class="info-line">
            <span class="info-name">{{$t('warning.video')}}:</span>
            <div class="video-box" v-if="selectWarning.videos && selectWarning.videos.length">
                <div class="video-list" v-for="(item, index) in selectWarning.videos" :key="item + index" 
                    @click="openViewVideoDialog(item)">
                    <img class="video-list-img" src="@/assets/images/alarmvideo-bg.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="warning-detial-box-btn" v-if="isAI && selectWarning.alarmStatus === 'pend'">
        <el-button size="small" :loading="btnLoading.ignore" @click="ignore">{{$t('warning.ignore')}}</el-button>
        <el-button type="primary" size="small" @click="corrections">{{$t('warning.corrections')}}</el-button>
        <el-button type="primary" size="small" @click="confirm">{{$t('warning.confirm')}}</el-button>
    </div>
    <!-- 更改AI报警信息弹窗 -->
    <el-dialog class="correctDialog" :title="!isConfirm ? $t('warning.correctAlarm') : $t('warning.confirmAlarm')" 
        :visible.sync="correctDialog" width="450px" :modal-append-to-body="false" :close-on-click-modal="false" 
        top="20vh" :before-close="handleCloseCorrect">
        <div class="content">
            <el-form :model="correctForm" :rules="correctRules" ref="correctForm" label-width="auto" class="demo-ruleForm">
                <el-form-item :label="$t('warning.type') + '：'" prop="alarmType" v-if="!isConfirm">
                    <el-select v-model="correctForm.alarmType" size="small" 
                        :placeholder="$t('warning.type')" style="width: 100%;">
                        <el-option v-for="(item, index) in alarmTypeList" :label="item.dictValue"
                            :value="item.dictKey" :key="index + item.dictKey" />
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('warning.alarmLevel') + '：'" prop="alarmLevel">
                    <el-select v-model="correctForm.alarmLevel" size="small" 
                        :placeholder="$t('warning.alarmLevel')" style="width: 100%;">
                        <el-option v-for="(item, index) in alarmLevelList" :label="item.label"
                            :value="item.value" :key="index + item.value" />
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleCloseCorrect">{{$t('btnCancel')}}</el-button>
            <el-button size="small" type="primary" :loading="btnLoading.corrections || btnLoading.confirm" 
                @click="submitCorrect">{{$t('btnOk')}}</el-button>
        </span>
    </el-dialog>
    <!-- 查看报警视频弹窗 -->
    <el-dialog class="viewVideoDialog" :title="$t('warning.video')" :visible.sync="viewVideoDialog" width="680px" 
        v-if="viewVideoDialog" :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" 
        :before-close="handleCloseVideo">
        <div class="content">
            <xg-player class="videoPlayer-xg-player" v-if="viewVideoUrl" ref="videoLive" :videoUrl="viewVideoUrl" 
                :videoType="videoType(viewVideoUrl)" :controls="true" :screen="false" :showDownLoad="false" 
                :show="true" :isShowMenu="false" />
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import XgPlayer from '@/components/video-live-xgplayer';
import { getDictionary } from "@/api/system/dictbiz";
import { editAIAlarmData, getProjectDetail } from "@/api/project/index";

export default {
    name: 'WarningDetialBox',
    components: { XgPlayer },
    props: {
        top: {
            type: Number,
            default(){
                return 0
            } 
        },
        right: {
            type: Number,
            default(){
                return 0
            } 
        },
        selectWarning: {
            type: Object,
            default() {
                return {}
            }
        },
        isAI: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imgDialogVisible: false,
            activeImg: 0,
            btnLoading: {
                ignore: false,
                corrections: false,
                confirm: false
            },
            correctDialog: false,
            isConfirm: false,
            alarmTypeList: [],
            alarmLevelList: [
                {label: this.$t('warning.levelOne'), value: 1},
                {label: this.$t('warning.levelTwo'), value: 2},
                {label: this.$t('warning.levelThree'), value: 3},
                {label: this.$t('warning.levelFour'), value: 4}
            ],
            correctForm: {
                alarmType: '',
                alarmLevel: ''
            },
            correctRules: {
                alarmType: [{required: true, message: this.$t('warning.type'), trigger: 'change'}],
                alarmLevel: [{required: true, message: this.$t('warning.alarmLevel'), trigger: 'change'}]
            },
            viewVideoDialog: false,
            viewVideoUrl: ''
        }
    },
    computed: {
        ...mapGetters([
            'showWarningDetialDialog'
        ]),
        alarmLevel() {
            return function(data) {
                if (data == 1) {
                    return this.$t('warning.levelOne');
                } else if (data == 2) {
                    return this.$t('warning.levelTwo');
                } else if (data == 3) {
                    return this.$t('warning.levelThree');
                } else if (data == 4) {
                    return this.$t('warning.levelFour');
                }
                return data;
            }
        },
        videoType() {
            return function(data) {
                return data.substr(-3);
            }
        }
    },
    methods: {
        getProjectDetail() {
            const id = this.$route.query.id || this.$route.query.projectId;
            getProjectDetail({id}).then((res) => {
                if (res.data.code === 200) {
                    this.getDictList(res.data.data.industryType); // 获取报警类型列表
                }
            });
        },
        getDictList(industryType) {
            getDictionary({code: industryType}).then(res => {
                if (res.data.code == 200) {
                    this.alarmTypeList = res.data.data || [];
                }
            });
        },
        close(){
            this.$emit('close')
            this.btnLoading = {
                ignore: false,
                corrections: false,
                confirm: false
            };
        },
        handleClose() {
            this.imgDialogVisible = false
            let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
            let closeBtn = document.querySelector(".el-image-viewer__close")
            if (!domImageView && !closeBtn) {
                return;
            }
            domImageView.removeEventListener("click", this.handleClose);
            closeBtn.removeEventListener("click", this.handleClose);
        },
        showImgDialog(str) {
            this.imgDialogVisible = true
            this.$nextTick(() => {
                let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
                let closeBtn = document.querySelector(".el-image-viewer__close")
                if (!domImageView && !closeBtn) {
                    return;
                }
                domImageView.addEventListener("click", this.handleClose);
                closeBtn.addEventListener("click", this.handleClose);
            });
        },
        ignore() {
            let params = {
                id: this.selectWarning.id,
                alarmStatus: 'ignore'
            };
            this.btnLoading = {
                ignore: true,
                corrections: false,
                confirm: false
            };
            editAIAlarmData(params).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(this.$t('requestSuccessText'));
                    this.close();
                    this.$emit('updataList');
                }
            }).finally(() => {
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: false
                };
            });
        },
        corrections() {
            this.getProjectDetail();
            this.correctForm = {
                alarmType: this.selectWarning.finalTypeCode,
                alarmLevel: this.selectWarning.level || 3
            };
            this.isConfirm = false;
            this.correctDialog = true;
        },
        confirm() {
            this.correctForm = {
                alarmLevel: this.selectWarning.level || 3
            };
            this.isConfirm = true;
            this.correctDialog = true;
        },
        handleCloseCorrect() {
            this.correctDialog = false;
            this.correctForm = {
                alarmType: '',
                alarmLevel: ''
            };
            this.btnLoading = {
                ignore: false,
                corrections: false,
                confirm: false
            };
            this.isConfirm = false;
        },
        submitCorrect() {
            let params;
            if (!this.isConfirm) {
                params = {
                    id: this.selectWarning.id,
                    alarmStatus: 'amend',
                    finalTypeCode: this.correctForm.alarmType,
                    level: this.correctForm.alarmLevel
                };
                let dictItem = this.alarmTypeList.find(t => t.dictKey === this.correctForm.alarmType);
                if (dictItem) {
                    params.finalTypeName = dictItem.dictValue;
                }
                this.btnLoading = {
                    ignore: false,
                    corrections: true,
                    confirm: false
                };
            } else {
                params = {
                    id: this.selectWarning.id,
                    alarmStatus: 'confirm',
                    level: this.correctForm.alarmLevel
                };
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: true
                };
            }
            editAIAlarmData(params).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(this.$t('requestSuccessText'));
                    this.handleCloseCorrect();
                    this.close();
                    this.$emit('updataList');
                }
            }).finally(() => {
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: false
                };
            });
        },
        openViewVideoDialog(url) {
            this.viewVideoUrl = url;
            this.viewVideoDialog = true;
        },
        handleCloseVideo() {
            this.viewVideoDialog = false;
            this.viewVideoUrl = '';
        }
    }
}
</script>

<style lang="scss">
@import '../../../styles/variables.scss';
.warning-detial-box {
    background-color: #0C0C0CCC;
    padding: 15px 33px 20px 33px;
    position: absolute;
    max-height: 80vh;
    width: 364px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI;
    color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .warning-detial-box-top {
        height: 0;
        flex: 1;
        overflow-y: auto;
        .info-line{
            .info-name {
                display: inline-block;
                vertical-align: top;
                height: 18px;
                line-height: 18px;
                width: 80px;
            }
            .info-content {
                display: inline-block;
                vertical-align: top;
                min-height: 18px;
                width: calc(100% - 80px);
                line-height: 18px;
            }
            .img-box{
                margin-top: 18px;
                img {
                    margin-right: 4px;
                    cursor: pointer;
                }
            }
            .video-box {
                display: flex;
                margin-top: 18px;
                overflow-x: auto;
                overflow-y: hidden;
                .video-list {
                    width: 96px;
                    min-width: 96px;
                    height: 50px;
                    margin-right: 10px;
                    cursor: pointer;
                    .video-list-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .info-line:not(:first-child){
            margin-top: 18px;
        }

        .icon-close{
            position: absolute;
            top: 33px;
            right: 33px;
            cursor: pointer;
        }

        .status-box {
            position: absolute;
            top: 30px;
            right: 70px;
            .status {
                font-family: 微软雅黑;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                color: rgb(255, 255, 255);
                margin-left: 10px;
                padding: 2px 8px;
                border-radius: 4px;
                &.untreated {
                    border: 1px solid #ffffff;
                }
                &.confirmed {
                    color: rgb(64, 158, 255);
                    background: #E6F7FF;
                    border: 1px solid rgb(64, 158, 255);
                }
                &.corrected {
                    color: rgb(245, 154, 35);
                    background: #E9DFD0;
                    border: 1px solid rgb(245, 154, 35);
                }
                &.neglected {
                    color: rgb(242, 242, 242);
                    background: #9A9B9F;
                    border: 1px solid #9A9B9F;
                }
            }
        }
    }
    .warning-detial-box-btn {
        height: 50px;
        margin-top: 20px;
        text-align: center;
    }
    .correctDialog {
        .el-dialog {
            background-color: rgba(30, 34, 42, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 15px 20px;
                .content {
                    .el-form-item__label {
                        color: #ffffff;
                    }
                    .el-input__inner {
                        font-size: 14px;
                        color: #ffffff;
                        background-color: transparent;
                        &::placeholder {
                            color:  rgba(153, 153, 153, 0.79);
                        }
                    }
                }
            }
        }
    }
    .viewVideoDialog {
        .el-dialog {
            background-color: rgba(0, 0, 0, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 0;
                .content {
                    width: 100%;
                    height: 325px;
                    .videoPlayer-xg-player {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>