var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.imgDialogVisible && !_vm.showWarningDetialDialog,
          expression: "!imgDialogVisible && !showWarningDetialDialog",
        },
      ],
      staticClass: "warning-detial-box",
      style: "top: " + _vm.top + "px; right: " + _vm.right + "px",
    },
    [
      _c("div", { staticClass: "warning-detial-box-top" }, [
        _c("i", {
          staticClass: "el-icon-close icon-close",
          on: { click: _vm.close },
        }),
        _vm.isAI
          ? _c("div", { staticClass: "status-box" }, [
              _vm.selectWarning.alarmStatus === "confirm"
                ? _c("span", { staticClass: "status confirmed" }, [
                    _vm._v(_vm._s(_vm.$t("warning.confirmed"))),
                  ])
                : _vm.selectWarning.alarmStatus === "amend"
                ? _c("span", { staticClass: "status corrected" }, [
                    _vm._v(_vm._s(_vm.$t("warning.corrected"))),
                  ])
                : _vm.selectWarning.alarmStatus === "ignore"
                ? _c("span", { staticClass: "status neglected" }, [
                    _vm._v(_vm._s(_vm.$t("warning.neglected"))),
                  ])
                : _c("span", { staticClass: "status untreated" }, [
                    _vm._v(_vm._s(_vm.$t("warning.untreated"))),
                  ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.type")) + ":"),
          ]),
          !_vm.isAI
            ? _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.alarmTypeName)),
              ])
            : _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.finalTypeName)),
              ]),
        ]),
        _vm.isAI && _vm.selectWarning.alarmStatus === "amend"
          ? _c("div", { staticClass: "info-line" }, [
              _c("span", { staticClass: "info-name" }, [
                _vm._v(_vm._s(_vm.$t("warning.correctionType")) + ":"),
              ]),
              _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.firstTypeName)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.place")) + ":"),
          ]),
          !_vm.isAI
            ? _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.address)),
              ])
            : _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.alarmAddress)),
              ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.device")) + ":"),
          ]),
          !_vm.isAI
            ? _c("span", { staticClass: "info-content" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(
                      _vm.selectWarning && _vm.selectWarning.uavName
                        ? _vm.selectWarning.uavName.join(",")
                        : ""
                    )
                ),
              ])
            : _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.selectWarning.equipmentName)),
              ]),
        ]),
        _vm.selectWarning.level
          ? _c("div", { staticClass: "info-line" }, [
              _c("span", { staticClass: "info-name" }, [
                _vm._v(_vm._s(_vm.$t("warning.alarmLevel")) + ":"),
              ]),
              _c("span", { staticClass: "info-content" }, [
                _vm._v(_vm._s(_vm.alarmLevel(_vm.selectWarning.level))),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("FilghtProjectName")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.projectName)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("FilghtTask")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.taskName)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("inspection.project")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.inpectionProjectName)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("inspection.selfName")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.inpectionObjectName)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.time")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.alarmTime)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("longitude")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.lng)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("latitude")) + ":"),
          ]),
          _c("span", { staticClass: "info-content" }, [
            _vm._v(_vm._s(_vm.selectWarning.lat)),
          ]),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.photo")) + ":"),
          ]),
          _vm.selectWarning.imgs && _vm.selectWarning.imgs.length
            ? _c(
                "div",
                { staticClass: "img-box" },
                _vm._l(_vm.selectWarning.imgs, function (item) {
                  return _c("el-image", {
                    key: item,
                    staticStyle: { width: "96px", "margin-right": "8px" },
                    attrs: {
                      fit: "fill",
                      src: item,
                      "preview-src-list": _vm.selectWarning.imgs,
                    },
                    on: {
                      close: _vm.handleClose,
                      click: function ($event) {
                        return _vm.showImgDialog(item)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "info-line" }, [
          _c("span", { staticClass: "info-name" }, [
            _vm._v(_vm._s(_vm.$t("warning.video")) + ":"),
          ]),
          _vm.selectWarning.videos && _vm.selectWarning.videos.length
            ? _c(
                "div",
                { staticClass: "video-box" },
                _vm._l(_vm.selectWarning.videos, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item + index,
                      staticClass: "video-list",
                      on: {
                        click: function ($event) {
                          return _vm.openViewVideoDialog(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "video-list-img",
                        attrs: {
                          src: require("@/assets/images/alarmvideo-bg.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm.isAI && _vm.selectWarning.alarmStatus === "pend"
        ? _c(
            "div",
            { staticClass: "warning-detial-box-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", loading: _vm.btnLoading.ignore },
                  on: { click: _vm.ignore },
                },
                [_vm._v(_vm._s(_vm.$t("warning.ignore")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.corrections },
                },
                [_vm._v(_vm._s(_vm.$t("warning.corrections")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(_vm._s(_vm.$t("warning.confirm")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "correctDialog",
          attrs: {
            title: !_vm.isConfirm
              ? _vm.$t("warning.correctAlarm")
              : _vm.$t("warning.confirmAlarm"),
            visible: _vm.correctDialog,
            width: "450px",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            top: "20vh",
            "before-close": _vm.handleCloseCorrect,
          },
          on: {
            "update:visible": function ($event) {
              _vm.correctDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "correctForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.correctForm,
                    rules: _vm.correctRules,
                    "label-width": "auto",
                  },
                },
                [
                  !_vm.isConfirm
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("warning.type") + "：",
                            prop: "alarmType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "small",
                                placeholder: _vm.$t("warning.type"),
                              },
                              model: {
                                value: _vm.correctForm.alarmType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.correctForm, "alarmType", $$v)
                                },
                                expression: "correctForm.alarmType",
                              },
                            },
                            _vm._l(_vm.alarmTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index + item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("warning.alarmLevel") + "：",
                        prop: "alarmLevel",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: _vm.$t("warning.alarmLevel"),
                          },
                          model: {
                            value: _vm.correctForm.alarmLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.correctForm, "alarmLevel", $$v)
                            },
                            expression: "correctForm.alarmLevel",
                          },
                        },
                        _vm._l(_vm.alarmLevelList, function (item, index) {
                          return _c("el-option", {
                            key: index + item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseCorrect },
                },
                [_vm._v(_vm._s(_vm.$t("btnCancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading:
                      _vm.btnLoading.corrections || _vm.btnLoading.confirm,
                  },
                  on: { click: _vm.submitCorrect },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.viewVideoDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "viewVideoDialog",
              attrs: {
                title: _vm.$t("warning.video"),
                visible: _vm.viewVideoDialog,
                width: "680px",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                top: "20vh",
                "before-close": _vm.handleCloseVideo,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.viewVideoDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.viewVideoUrl
                    ? _c("xg-player", {
                        ref: "videoLive",
                        staticClass: "videoPlayer-xg-player",
                        attrs: {
                          videoUrl: _vm.viewVideoUrl,
                          videoType: _vm.videoType(_vm.viewVideoUrl),
                          controls: true,
                          screen: false,
                          showDownLoad: false,
                          show: true,
                          isShowMenu: false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }